export const CalendarOptionsChoose = [
  {
    code: 'today',
    value: 'today',
    title: 'Today',
  },
  {
    code: 'yesterday',
    value: 'yesterday',
    title: 'Yesterday',
  },
  {
    code: 'past_week',
    value: 'pastWeek',
    title: 'Past 7 Days',
  },
  {
    code: 'past_month',
    value: 'pastMonth',
    title: 'Past 30 Days',
  },
];

export const CalendarOptionsSelect = [
  {
    code: 'by_day',
    value: 'byDay',
    title: 'By Day',
  },
  {
    code: 'by_week',
    value: 'byWeek',
    title: 'By Week',
  },
  {
    code: 'by_month',
    value: 'byMonth',
    title: 'By Month',
  },
  {
    code: 'by_year',
    value: 'byYear',
    title: 'By Year',
  },
];
