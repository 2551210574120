<div [formGroup]="parentFormGroup" class="t-tree-items-container">
  <p-accordion
    [multiple]="true"
    [activeIndex]="parentFormGroup.value.is_collapsed ? [] : [0]"
    (onOpen)="parentFormGroup.patchValue({is_collapsed: false}); emitOpenAccordion.emit()"
    (onClose)="parentFormGroup.patchValue({is_collapsed: true})"
  >
    <p-accordionTab>
      <ng-template pTemplate="header">
        <div class="flex items-center gap-5 w-full ml-3">
          <div>
            <img
              src="{{ parentFormGroup.value.image }}"
              alt=""
              class="min-w-[32px] w-8 h-8 aspect-square"
            />
          </div>
          <input
            formControlName="name"
            (keydown)="handlePreventCollapse($event)"
            (click)="handlePreventCollapse($event)"
            pInputText
            type="text"
            (keyup)="typeEdit(parentFormGroup)"
            placeholder="{{'section-action.enter' | translate }} {{'common.value' | translate}}"
            class="text-md font-normal text-gray-700 border border-gray-300 rounded-lg outline-none w-full h-11 py-2 px-4"
          />
          <div
            class="{{
              parentFormGroup.value.disabled
                ? 'cursor-default'
                : 'cursor-pointer'
            }}"
            (click)="$event.stopPropagation()"
          >
            <ng-container
              [ngTemplateOutlet]="treeButtonTemplate"
              [ngTemplateOutletContext]="{ item: parentFormGroup }"
            ></ng-container>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="content">
        <div class="flex flex-col gap-4">
          <div
            *ngIf="checkIsContainItems(approvedItems.value)"
            class="flex flex-col gap-4"
          >
            <h2 class="font-inter text-gray-700 font-semibold">
              {{ translatePrefix + "approved-items" | translate }}
            </h2>
            <div
              formArrayName="approvedItems"
              cdkDropList
              [cdkDropListData]="
                $any(this.approvedItems)
              "
              (cdkDropListDropped)="dropChild($event)"
              [cdkDropListAutoScrollStep]="10"
            >
              <div
                *ngFor="
                  let approvedItem of approvedItems.controls;
                  let approvedIndex = index
                "
              >
                <cdk-accordion-item [expanded]="false">
                  <div>
                    <div
                      *ngIf="!approvedItem.value.is_deleted"
                      [formGroup]="$any(approvedItem)"
                      class="flex items-center w-full gap-5 cursor-move"
                      [ngClass]="
                      approvedIndex !== 0 ? 'mt-4' : 'mt-0'
                      "
                      cdkDrag
                      (cdkDragStarted)="this.cdr.detectChanges()"
                      #approvedValue
                    >
                      <div
                        class="bg-gray-200 md:h-10 my-2 mx-8 border-2 border-gray-300 border-dotted rounded-lg"
                        *cdkDragPlaceholder
                      ></div>
                      <div cdkDragHandle class="cursor-move">
                        <i
                          class="sctr-icon-menu-05 text-gray-700 text-xl/none"
                        ></i>
                      </div>
                      <div>
                        <img
                          src="{{ approvedItem.value.image }}"
                          alt=""
                          class="min-w-[24px] w-6 h-6 aspect-square"
                        />
                      </div>
                      <div class="flex gap-4 w-full">
                        <input
                          formControlName="name"
                          pInputText
                          type="text"
                          (keyup)="typeEdit(approvedItem)"
                          placeholder="{{'section-action.enter' | translate }} {{'common.value' | translate}}"
                          class="text-md font-normal text-gray-700 border border-gray-300 rounded-lg outline-none {{
                            config.dropdown ? 'w-1/2' : 'w-full'
                          }} h-11 py-2 px-4"
                        />
                        <p-dropdown
                          *ngIf="config.dropdown"
                          class="t-car-type-dropdown w-1/2"
                          formControlName="selectedType"
                          [options]="carType"
                          optionLabel="name"
                          placeholder="{{'section-automaker-management.select-car-type' | translate}}"
                          (onChange)="typeEdit(approvedItem)"
                        ></p-dropdown>
                      </div>
                      <div
                        class="{{
                          approvedItem.value.disabled
                            ? 'cursor-default'
                            : 'cursor-pointer'
                        }}"
                      >
                        <ng-container
                          [ngTemplateOutlet]="treeButtonTemplate"
                          [ngTemplateOutletContext]="{
                            item: approvedItem,
                            index: approvedIndex
                          }"
                        ></ng-container>
                      </div>
                    </div>
                  </div>
                </cdk-accordion-item>
              </div>
            </div>
          </div>
          <div
            class="flex items-center gap-1 cursor-pointer pl-[4.25rem] h-9 w-fit"
            (click)="addChildItem()"
          >
            <span class="text-sm font-semibold text-primary-600 px-4 py-2">
              {{ "section-action.add-item" | translate | sentenceCase }}
            </span>
          </div>
          <div
            *ngIf="checkIsContainItems(unapprovedItems.value)"
            class="border-b border-gray-300 mr-2"
          ></div>
          <div
            *ngIf="checkIsContainItems(unapprovedItems.value)"
            class="flex flex-col gap-4"
          >
            <h2 class="font-inter text-gray-700 font-semibold">
              {{ translatePrefix + "request-approve-items" | translate }}
            </h2>
            <div formArrayName="unapprovedItems">
              <div
                *ngFor="
                  let unapprovedItem of unapprovedItems.controls;
                  let unapprovedIndex = index
                "
              >
                <div>
                  <div
                    *ngIf="!unapprovedItem.value.is_deleted"
                    [formGroup]="$any(unapprovedItem)"
                    class="flex items-center w-full gap-5 cursor-default"
                    [ngClass]="
                      unapprovedIndex !== 0 ? 'mt-4' : 'mt-0'
                    "
                    #unapprovedValue
                  >
                    <div class="cursor-default pointer-events-none">
                      <i
                        class="sctr-icon-menu-05 text-gray-400 text-xl/none"
                      ></i>
                    </div>
                    <div>
                      <img
                        src="{{ unapprovedItem.value.image }}"
                        alt=""
                        class="min-w-[24px] w-6 h-6 aspect-square"
                      />
                    </div>
                    <div class="flex gap-4 w-full">
                      <input
                        formControlName="name"
                        pInputText
                        type="text"
                        (keyup)="typeEdit(unapprovedItem)"
                        placeholder="{{'section-action.enter' | translate }} {{'common.value' | translate}}"
                        class="text-md font-normal text-gray-700 border border-gray-300 rounded-lg outline-none {{
                          config.dropdown ? 'w-1/2' : 'w-full'
                        }} h-11 py-2 px-4"
                      />
                      <p-dropdown
                        *ngIf="config.dropdown"
                        class="t-car-type-dropdown w-1/2"
                        formControlName="selectedType"
                        [options]="carType"
                        optionLabel="name"
                        placeholder="Select car type"
                        (onChange)="typeEdit(unapprovedItem)"
                      ></p-dropdown>
                    </div>
                    <div
                      class="{{
                        unapprovedItem.value.disabled
                          ? 'cursor-default'
                          : 'cursor-pointer'
                      }}"
                    >
                      <ng-container
                        [ngTemplateOutlet]="treeButtonTemplate"
                        [ngTemplateOutletContext]="{
                          item: unapprovedItem,
                          index: unapprovedIndex
                        }"
                      ></ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-accordionTab>
  </p-accordion>
</div>

<ng-container>
  <ng-template
    #treeButtonTemplate
    let-item="item"
    let-index="index"
  >
    <div
      *ngIf="
        item.value.status === adminStatus.PENDING_APPROVAL;
        else defaultTemp
      "
      class="flex items-center gap-2"
    >
      <button
        class="px-2 py-2 rounded-lg bg-transparent border-transparent flex items-center justify-center"
        (click)="handleActions(item, 'APPROVE')"
      >
        <span
          *ngIf="
            !isApproving ||
              (selectedChildItem &&
                selectedChildItem?.value?.id !== item.value.id);
            else loadingTemp
          "
          class="font-inter text-sm font-semibold text-primary-600"
        >
          {{ "section-action.approve" | translate | sentenceCase }}
        </span>
      </button>

      <div>
        <button
          *ngIf="
            editingItemValue?.id === item.value?.id;
            else rejectTemplate
          "
          class="px-2 py-2 rounded-lg w-max bg-transparent border-transparent flex items-center justify-center"
          (click)="handleCancelEdit(item, index)"
        >
          <span class="font-inter text-sm font-semibold text-gray-600">
            {{ "section-action.cancel" | translate | sentenceCase }}
          </span>
        </button>
        <ng-template #rejectTemplate>
          <button
            class="px-2 py-2 rounded-lg w-max bg-transparent border-transparent flex items-center justify-center"
            (click)="handleActions(item, 'REJECT')"
          >
            <span class="font-inter text-sm font-semibold text-error-600">
              {{ "section-action.reject" | translate | sentenceCase }}
            </span>
          </button>
        </ng-template>
      </div>
    </div>
    <ng-template #defaultTemp>
      <div
        *ngIf="
          editingItemValue && editingItemValue.id === item.value?.id;
          else deleteBtn
        "
        class="flex items-center"
      >
        <button
          class="px-2 py-2 rounded-lg bg-transparent border-transparent flex items-center justify-center"
          (click)="handleActions(item, 'EDIT')"
          [disabled]="isEditing"
        >
          <span
            *ngIf="!isEditing; else loadingTemp"
            class="font-inter text-sm font-semibold text-primary-600"
          >
            {{ "section-action.save" | translate | sentenceCase }}
          </span>
        </button>
        <button
          class="px-2 py-2 rounded-lg bg-transparent border-transparent flex items-center justify-center"
          (click)="handleCancelEdit(item, index)"
          [disabled]="isEditing"
        >
          <span class="font-inter text-sm font-semibold text-gray-600">
            {{ "section-action.cancel" | translate | sentenceCase }}
          </span>
        </button>
      </div>
      <ng-template #deleteBtn>
        <button
          class="px-2 bg-transparent border-transparent flex items-center justify-center {{
            item.value.disabled
              ? 'cursor-default pointer-events-none'
              : 'cursor-pointer pointer-events-auto'
          }}"
          (click)="handleActions(item, 'DELETE')"
        >
          <i
            class="sctr-icon-trash-01 text-xl/5 {{
              item.value.disabled
                ? 'pointer-events-none cursor-default text-gray-500'
                : 'cursor-pointer text-error-600'
            }} "
          ></i>
        </button>
      </ng-template>
    </ng-template>
    <ng-template #loadingTemp>
      <p-progressSpinner
        styleClass="!w-[1.25rem] !h-[1.25rem]"
      ></p-progressSpinner>
    </ng-template>
  </ng-template>
</ng-container>
