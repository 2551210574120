import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';

export enum ADMIN_STATUS {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export interface ConfigAutoMaker {
  deleteBtn: boolean;
  iconDeleteClass: string;
  dropdown: boolean;
  edit?: boolean;
}
export interface AutoMaker {
  order_value: number;
  id?: string;
  name?: string;
  status?: string;
  image?: string;
  disabled?: boolean;
  is_edited?: boolean;
  is_deleted?: boolean;
  is_collapsed?: boolean;
  // options?: OptionModule[];
  approvedItems?: OptionModule[];
  unapprovedItems?: OptionModule[];
}
export interface OptionModule {
  order_value: number;
  id?: string;
  value?: string;
  selectedType?: string;
  automaker_id?: string;
  status?: string;
  image?: string;
  disabled?: boolean;
  is_edited?: boolean;
  is_deleted?: boolean;
}
export interface OptionDropdown {
  name: string;
}
export interface IBtnTemplate {
  approvedTemplate: TemplateRef<any> | null;
  deleteTemplate: TemplateRef<any> | null;
  editTemplate: TemplateRef<any> | null;
}

@Component({
  selector: 'app-view-automaker',
  templateUrl: './view-automaker.component.html',
  styleUrls: ['./view-automaker.component.scss'],
  providers: [ConfirmationService, MessageService],
  encapsulation: ViewEncapsulation.None,
})
export class ViewAutomakerComponent {
  @Input() optionsFormArray: any;
  @Input() optionFormGroup: any;
  @Input() optionIndex: number;
  @Input() config: ConfigAutoMaker = {
    deleteBtn: true,
    iconDeleteClass: 'sctr-icon-trash-01 text-error-700',
    dropdown: true,
    edit: false,
  };
  @Input() carType: OptionDropdown[] = [{ name: 'SUV' }, { name: 'Sedan' }];
  @Output() emitOnDeleteOptionOrder = new EventEmitter<number>();
  @Output() emitOnDeleteOptionValue = new EventEmitter<number>();
  @Output() emitOnDropOptionValue = new EventEmitter<any>();
  @Output() emitAddOptionValue = new EventEmitter<any>();
  @Output() emitEditInput = new EventEmitter<any>();
  @Output() emitActionOption = new EventEmitter<any>();
  @Output() emitOpenAccordion = new EventEmitter<any>();
  @Output() emitCancelEdit = new EventEmitter<any>();

  isCollapsed: boolean = false;
  private destroyed$ = new Subject<void>();
  optionForm: FormGroup;
  selectedCarType: any;
  editingOption: any;
  adminStatus = ADMIN_STATUS;
  isApproving = false;
  isEditing = false;
  initalData: AutoMaker;
  selectedOptionValue: FormControl | undefined;
  translatePrefix = 'ota-config-management.';

  get approvedItems(): FormArray {
    return this.optionForm.get('approvedItems') as FormArray;
  }
  get unapprovedItems(): FormArray {
    return this.optionForm.get('unapprovedItems') as FormArray;
  }

  constructor(
    public cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    public confirmationService: ConfirmationService,
  ) {
    this.optionForm = this.fb.group({
      id: '',
      name: [''],
      image: '',
      status: '',
      order_value: '',
      is_collapsed: true,
      is_deleted: false,
      approvedItems: this.fb.array([]),
      unapprovedItems: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.initalData = this.optionFormGroup.value;
    this.setInitialData(); // Set initial data on component initialization
  }

  setInitialData(): void {
    const approvedData = this.optionFormGroup.value.approvedItems;
    const unapprovedData = this.optionFormGroup.value.unapprovedItems;

    if (approvedData?.length) {
      approvedData.forEach((data: OptionModule) => {
        this.approvedItems.push(this.createOptionValue(data));
      });
    }
    if (unapprovedData?.length) {
      unapprovedData.forEach((data: OptionModule) => {
        this.unapprovedItems.push(this.createOptionValue(data));
      });
    }
    if (!this.config.edit) {
      this.optionFormGroup.get('name')?.disable();
    }
    this.optionForm.patchValue({
      id: this.optionFormGroup.value.id,
      name: this.optionFormGroup.value.name,
      image: this.optionFormGroup.value.image,
      status: this.optionFormGroup.value.status,
      order_value: this.optionFormGroup.value.order_value,
      is_collapsed: this.optionFormGroup.value.is_collapsed,
      is_deleted: this.optionFormGroup.value.is_deleted,
    });
  }

  createOptionValue(data: OptionModule): FormGroup {
    return this.fb.group({
      value: [data.value, Validators.required],
      selectedType: { name: data.selectedType },
      status: data.status,
      image: data.image,
      id: data?.id,
      disabled: data?.disabled,
      order_value: data.order_value,
      automaker_id: data.automaker_id,
      is_deleted: data.is_deleted,
    });
  }

  ngAfterViewInit(): void {
    // this.validateOptionNameIsExist(this.optionForm.controls['name'].value);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  validateOptionNameIsExist(optionName: string) {
    if (this.optionsFormArray && this.optionsFormArray.value) {
      let exist =
        this.optionsFormArray.value.filter(
          (i: any) =>
            optionName && optionName.trim() !== '' && i.name === optionName,
        ).length >= 2;
      if (exist) {
        this.f['name'].setErrors({ duplicated: true });
        if (!this.optionsFormArray?.errors?.['duplicated'])
          this.optionsFormArray.setErrors({ duplicated: true });
        this.f['name'].markAsTouched();
      } else {
        if (this.optionsFormArray?.errors?.['duplicated'])
          this.optionsFormArray.setErrors(null);
        this.f['name'].setErrors(null);
      }
    }
  }

  deleteOptionHandel(index: number) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete ${this.optionForm.value.name}?`,
      header: 'Confirm delete car model',
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      accept: () => {
        this.deleteOption(index);
      },
      reject: () => {},
    });
  }

  deleteOption(index: number) {
    const deletedOption = this.optionsFormArray.at(index);
    this.optionsFormArray.removeAt(index);
    this.emitOnDeleteOptionOrder.emit(deletedOption.value);
  }

  get optionValues() {
    return this.optionForm.get('option_values') as FormArray;
  }

  addOptionValue() {
    const optionGroup = this.fb.group({
      value: ['', Validators.required],
      selectedType: [''],
      status: 'create',
      image: '',
    });
    this.optionValues.push(optionGroup);
  }

  addOptionValueHandle() {
    this.emitAddOptionValue.emit();
  }

  deleteOptionValueHandel(index: number) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete ${this.optionForm.value.option_values[index].value}?`,
      header: 'Confirm delete car model',
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      accept: () => {
        this.deleteOptonValue(index);
      },
      reject: () => {},
    });
  }

  deleteOptonValue(index: number) {
    const deletedValue = this.optionValues.at(index);
    this.optionValues.removeAt(index);
    this.emitOnDeleteOptionValue.emit(deletedValue.value);
  }

  drop(event: CdkDragDrop<any>) {
    this.moveItemInFormArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );
    // this.reorderOptionValue(event.container.data.controls);
    // this.emitOnDropOptionValue.emit(this.optionForm.value);
  }

  moveItemInFormArray(
    formArray: FormArray,
    srcIndex: number,
    targetIndex: number,
  ): void {
    const formGroup = formArray.at(srcIndex) as FormGroup;
    formArray.removeAt(srcIndex);
    formArray.insert(targetIndex, formGroup);
    formArray.markAsDirty();
    this.emitOnDropOptionValue.emit({ formGroup, srcIndex, targetIndex });
  }

  handlePreventCollapse(event: Event) {
    event.stopPropagation();
  }

  reorderOptionValue(optionValueArray: any) {
    optionValueArray.forEach((item: any, index: number) => {
      if (item.get('order_value')) {
        const order_value = item.get('order_value').value;
        item.get('order_value').setValue(order_value + 1);
        item?.markAsDirty();
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.optionForm.controls;
  }

  typeEdit(item: any): void {
    // this.optionFormGroup.patchValue({ name: item.name, selectedType: item.selectedType })
    this.editingOption = item;
  }

  handleActions(
    optionValue: FormControl,
    type: 'APPROVE' | 'REJECT' | 'DELETE' | 'EDIT',
  ) {
    switch (type) {
      case 'APPROVE':
        this.isApproving = true;
        this.selectedOptionValue = optionValue;
        break;
      case 'REJECT':
        this.selectedOptionValue = optionValue;
        break;
      case 'EDIT':
        this.isEditing = true;
        break;
      case 'DELETE':
        break;
    }
    this.emitActionOption.emit({ optionValue, type, editingOption: this.editingOption });
  }

  handleCancelEdit(item: any, optionIndex?: number) {
    if (optionIndex !== undefined) {
      if (item.value?.status === ADMIN_STATUS.APPROVED) {
        const initialItem = this.initalData.approvedItems![optionIndex];
        item.patchValue({
          value: initialItem.value,
          selectedType: { name: initialItem.selectedType },
        });
      } else {
        const initialItem = this.initalData.unapprovedItems![optionIndex];
        item.patchValue({
          value: initialItem.value,
          selectedType: { name: initialItem.selectedType },
        });
      }
    } else {
      item.patchValue({
        name: this.initalData.name,
      });
      this.emitCancelEdit.emit(item);
    }
    this.editingOption = undefined;
  }

  checkIsContainItems(items: any[]): boolean {
    return items.filter(item => !item.is_deleted).length > 0;
  }
}
