import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DateTime } from 'luxon';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DateFilter, DateFilterType } from 'src/app/core/enum/date-filter.enum';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { CustomDateTimeService } from './../../../core/services/custom-date-time.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent {
  protected readonly SoctripIcons = SoctripIcons;
  private customDateTimeService = inject(CustomDateTimeService);
  @Input({ required: true }) rangeDates: (Date | undefined)[] = [];
  @Output() rangeDatesChange = new EventEmitter<(Date | undefined)[]>();
  @Output() onApply = new EventEmitter();

  DFActive?: DateFilterType;

  DF = DateFilter;
  dateFrom: Date | undefined;
  dateTo: Date | undefined;

  ngOnChanges() {
    if (this.rangeDates[0] && this.rangeDates[1]) {
      this.dateFrom = this.rangeDates[0];
      this.dateTo = this.rangeDates[1];
    }
  }

  formatDateString(date: Date) {
    return this.customDateTimeService.formatDate(DateTime.fromJSDate(date));
  }

  get dateString() {
    if (this.DFActive === DateFilterType.ALL_TIME) {
      return 'All time';
    }
    if (!this.rangeDates[0] && !this.rangeDates[1]) return '';
    return [
      this.rangeDates[0] ? this.formatDateString(this.rangeDates[0]) : '',
      this.rangeDates[1] ? this.formatDateString(this.rangeDates[1]) : '',
    ]
      .filter((item) => item)
      .join(' - ');
  }

  filterDate(item: { value: DateFilterType }) {
    let date1, date2: Date | undefined;
    const oneDayQuantity = 1000 * 60 * 60 * 24;
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();

    switch (item.value) {
      case DateFilterType.TODAY:
        date1 = new Date();
        date2 = new Date();
        break;

      case DateFilterType.YESTERDAY:
        date1 = new Date(new Date().getTime() - oneDayQuantity);
        date2 = new Date(new Date().getTime() - oneDayQuantity);
        break;

      case DateFilterType.THIS_WEEK:
        const firstDayOfWeek = DateTime.fromJSDate(currentDate)
          .startOf('week')
          .plus({ day: 1 })
          .toJSDate();
        const lastDayOfWeek = DateTime.fromJSDate(currentDate)
          .endOf('week')
          .plus({ day: 1 })
          .toJSDate();
        date1 = firstDayOfWeek;
        date2 = lastDayOfWeek;
        break;

      case DateFilterType.LAST_WEEK:
        const firstDayOfLastWeek = new Date(currentDate);
        const daysToSubtract =
          (currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1) + 7;
        firstDayOfLastWeek.setDate(currentDate.getDate() - daysToSubtract);
        const lastDayOfLastWeek = new Date(firstDayOfLastWeek);
        lastDayOfLastWeek.setDate(firstDayOfLastWeek.getDate() + 6);
        date1 = firstDayOfLastWeek;
        date2 = lastDayOfLastWeek;
        break;

      case DateFilterType.THIS_MONTH:
        date1 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        date2 = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0,
        );
        break;

      case DateFilterType.LAST_MONTH:
        const firstDayOfCurrentMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1,
        );
        const firstDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
        firstDayOfPreviousMonth.setMonth(
          firstDayOfPreviousMonth.getMonth() - 1,
        );
        const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
        lastDayOfPreviousMonth.setDate(0);
        date1 = new Date(firstDayOfPreviousMonth);
        date2 = new Date(lastDayOfPreviousMonth);
        break;

      case DateFilterType.THIS_YEAR:
        date1 = new Date(currentDate.getFullYear(), 0, 1);
        date2 = new Date(currentDate.getFullYear(), 11, 31);
        break;

      case DateFilterType.LAST_YEAR:
        date1 = new Date(currentDate.getFullYear() - 1, 0, 1);
        date2 = new Date(currentDate.getFullYear() - 1, 11, 31);
        break;

      case DateFilterType.ALL_TIME:
        date1 = undefined;
        date2 = undefined;
        break;
    }
    this.rangeDates = [date1, date2];
    [this.dateFrom, this.dateTo] = [date1, date2];
    this.DFActive = item.value;
  }

  getDateFilterArray(dateArr: Date[] | undefined[]) {
    [this.dateFrom, this.dateTo] = dateArr;
  }

  setDateFilter(event: any = undefined) {
    if (!this.dateFrom && !this.dateTo) return;
    if (!this.dateFrom) {
      this.dateFrom = this.dateTo;
      this.dateTo = undefined;
    }
    if (this.dateTo! < this.dateFrom!) this.dateTo = undefined;
    this.rangeDates = [this.dateFrom || this.dateTo, this.dateTo];
  }

  cancelDateFilter(op?: OverlayPanel) {
    if (op) {
      op.hide();
      return;
    }
    this.dateFrom = undefined;
    this.dateTo = undefined;
    this.rangeDates = [];
  }
}
