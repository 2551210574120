<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
<div [formGroup]="optionFormGroup" class="t-view-automaker">
  <p-accordion
    [multiple]="true"
    [activeIndex]="optionFormGroup.value.is_collapsed ? [] : [0]"
    (onOpen)="isCollapsed = false; emitOpenAccordion.emit()"
    (onClose)="isCollapsed = true"
  >
    <p-accordionTab>
      <ng-template pTemplate="header">
        <div class="flex items-center gap-5 w-full ml-3">
          <div>
            <img
              src="{{ optionFormGroup.value.image }}"
              alt=""
              class="min-w-[32px] w-8 h-8 aspect-square"
            />
          </div>
          <input
            formControlName="name"
            (keydown)="handlePreventCollapse($event)"
            (click)="handlePreventCollapse($event)"
            pInputText
            type="text"
            (keyup)="typeEdit(optionFormGroup.value)"
            placeholder="Enter automaker"
            class="text-md font-normal text-gray-700 border border-gray-300 rounded-lg outline-none w-full h-11 py-2 px-4"
          />
          <div
            class="{{
              optionFormGroup.value.disabled
                ? 'cursor-default'
                : 'cursor-pointer'
            }}"
            (click)="$event.stopPropagation()"
          >
            <ng-container
              [ngTemplateOutlet]="amenityItemTemplate"
              [ngTemplateOutletContext]="{ optionValue: optionFormGroup }"
            ></ng-container>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="content">
        <div class="flex flex-col gap-4">
          <div
            *ngIf="checkIsContainItems(approvedItems.value)"
            class="flex flex-col gap-4"
          >
            <h2 class="font-inter text-gray-700 font-semibold">
              {{ translatePrefix + "approved-items" | translate }}
            </h2>
            <div
              formArrayName="approvedItems"
              cdkDropList
              [cdkDropListData]="
                $any(this.optionForm.get('approvedItems'))
              "
              (cdkDropListDropped)="drop($event)"
              [cdkDropListAutoScrollStep]="10"
            >
              <div
                [formGroupName]="approvedOptionValueIndex"
                *ngFor="
                  let approvedOptionValue of approvedItems.controls;
                  let approvedOptionValueIndex = index
                "
              >
                <cdk-accordion-item [expanded]="false">
                  <div>
                    <div
                      *ngIf="!approvedOptionValue.value.is_deleted"
                      [formGroup]="$any(approvedOptionValue)"
                      class="flex items-center w-full gap-5 cursor-move"
                      [ngClass]="
                        approvedOptionValueIndex !== 0 ? 'mt-4' : 'mt-0'
                      "
                      cdkDrag
                      (cdkDragStarted)="this.cdr.detectChanges()"
                      #approvedValue
                      [cdkDragDisabled]="
                        approvedOptionValue.value.status !==
                        adminStatus.APPROVED
                      "
                    >
                      <div
                        class="bg-gray-200 md:h-10 my-2 mx-8 border-2 border-gray-300 border-dotted rounded-lg"
                        *cdkDragPlaceholder
                      ></div>
                      <div cdkDragHandle class="cursor-move">
                        <i
                          class="sctr-icon-menu-05 text-gray-700 text-xl/none"
                        ></i>
                      </div>
                      <div>
                        <img
                          src="{{ approvedOptionValue.value.image }}"
                          alt=""
                          class="min-w-[24px] w-6 h-6 aspect-square"
                        />
                      </div>
                      <div class="flex gap-4 w-full">
                        <input
                          formControlName="value"
                          pInputText
                          type="text"
                          (keyup)="typeEdit(approvedOptionValue.value)"
                          placeholder="Enter car model"
                          class="text-md font-normal text-gray-700 border border-gray-300 rounded-lg outline-none {{
                            config.dropdown ? 'w-1/2' : 'w-full'
                          }} h-11 py-2 px-4"
                        />
                        <p-dropdown
                          *ngIf="config.dropdown"
                          class="t-car-type-dropdown w-1/2"
                          formControlName="selectedType"
                          [options]="carType"
                          optionLabel="name"
                          placeholder="Select car type"
                          (onChange)="typeEdit(approvedOptionValue.value)"
                        ></p-dropdown>
                      </div>
                      <div
                        class="{{
                          approvedOptionValue.value.disabled
                            ? 'cursor-default'
                            : 'cursor-pointer'
                        }}"
                      >
                        <ng-container
                          [ngTemplateOutlet]="amenityItemTemplate"
                          [ngTemplateOutletContext]="{
                            optionValue: approvedOptionValue,
                            index: approvedOptionValueIndex
                          }"
                        ></ng-container>
                      </div>
                    </div>
                  </div>
                </cdk-accordion-item>
              </div>
            </div>
          </div>
          <div
            class="flex items-center gap-1 cursor-pointer pl-[4.25rem] h-9 w-fit"
            (click)="addOptionValueHandle()"
          >
            <span class="text-sm font-semibold text-primary-600 px-4 py-2">
              {{ "section-action.add-item" | translate | sentenceCase }}
            </span>
          </div>
          <div
            *ngIf="checkIsContainItems(unapprovedItems.value)"
            class="border-b border-gray-300 mr-2"
          ></div>
          <div
            *ngIf="checkIsContainItems(unapprovedItems.value)"
            class="flex flex-col gap-4"
          >
            <h2 class="font-inter text-gray-700 font-semibold">
              {{ translatePrefix + "request-approve-items" | translate }}
            </h2>
            <div formArrayName="unapprovedItems">
              <div
                [formGroupName]="unapprovedOptionValueIndex"
                *ngFor="
                  let unapprovedOptionValue of unapprovedItems.controls;
                  let unapprovedOptionValueIndex = index
                "
              >
                <div>
                  <div
                    *ngIf="!unapprovedOptionValue.value.is_deleted"
                    [formGroup]="$any(unapprovedOptionValue)"
                    class="flex items-center w-full gap-5 cursor-default"
                    [ngClass]="
                      unapprovedOptionValueIndex !== 0 ? 'mt-4' : 'mt-0'
                    "
                    #unapprovedValue
                  >
                    <div class="cursor-default pointer-events-none">
                      <i
                        class="sctr-icon-menu-05 text-gray-400 text-xl/none"
                      ></i>
                    </div>
                    <div>
                      <img
                        src="{{ unapprovedOptionValue.value.image }}"
                        alt=""
                        class="min-w-[24px] w-6 h-6 aspect-square"
                      />
                    </div>
                    <div class="flex gap-4 w-full">
                      <input
                        formControlName="value"
                        pInputText
                        type="text"
                        (keyup)="typeEdit(unapprovedOptionValue.value)"
                        placeholder="Enter car model"
                        class="text-md font-normal text-gray-700 border border-gray-300 rounded-lg outline-none {{
                          config.dropdown ? 'w-1/2' : 'w-full'
                        }} h-11 py-2 px-4"
                      />
                      <p-dropdown
                        *ngIf="config.dropdown"
                        class="t-car-type-dropdown w-1/2"
                        formControlName="selectedType"
                        [options]="carType"
                        optionLabel="name"
                        placeholder="Select car type"
                        (onChange)="typeEdit(unapprovedOptionValue.value)"
                      ></p-dropdown>
                    </div>
                    <div
                      class="{{
                        unapprovedOptionValue.value.disabled
                          ? 'cursor-default'
                          : 'cursor-pointer'
                      }}"
                    >
                      <ng-container
                        [ngTemplateOutlet]="amenityItemTemplate"
                        [ngTemplateOutletContext]="{
                          optionValue: unapprovedOptionValue,
                          index: unapprovedOptionValueIndex
                        }"
                      ></ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-accordionTab>
  </p-accordion>
</div>

<ng-container>
  <ng-template
    #amenityItemTemplate
    let-optionValue="optionValue"
    let-index="index"
  >
    <div
      *ngIf="
        optionValue.value.status === adminStatus.PENDING_APPROVAL;
        else defaultTemp
      "
      class="flex items-center gap-2"
    >
      <button
        class="px-2 py-2 rounded-lg bg-transparent border-transparent flex items-center justify-center"
        (click)="handleActions(optionValue, 'APPROVE')"
      >
        <span
          *ngIf="
            !isApproving ||
              (selectedOptionValue &&
                selectedOptionValue?.value?.id !== optionValue.value.id);
            else loadingTemp
          "
          class="font-inter text-sm font-semibold text-primary-600"
        >
          {{ "section-action.approve" | translate | sentenceCase }}
        </span>
      </button>

      <div>
        <button
          *ngIf="
            editingOption?.id === optionValue.value?.id;
            else rejectTemplate
          "
          class="px-2 py-2 rounded-lg w-max bg-transparent border-transparent flex items-center justify-center"
          (click)="handleCancelEdit(optionValue, index)"
        >
          <span class="font-inter text-sm font-semibold text-gray-600">
            {{ "section-action.cancel" | translate | sentenceCase }}
          </span>
        </button>
        <ng-template #rejectTemplate>
          <button
            class="px-2 py-2 rounded-lg w-max bg-transparent border-transparent flex items-center justify-center"
            (click)="handleActions(optionValue, 'REJECT')"
          >
            <span class="font-inter text-sm font-semibold text-error-600">
              {{ "section-action.reject" | translate | sentenceCase }}
            </span>
          </button>
        </ng-template>
      </div>
    </div>
    <ng-template #defaultTemp>
      <div
        *ngIf="
          editingOption && editingOption.id === optionValue.value?.id;
          else deleteBtn
        "
        class="flex items-center"
      >
        <button
          class="px-2 py-2 rounded-lg bg-transparent border-transparent flex items-center justify-center"
          (click)="handleActions(optionValue, 'EDIT')"
        >
          <span
            *ngIf="!isEditing; else loadingTemp"
            class="font-inter text-sm font-semibold text-primary-600"
          >
            {{ "section-action.save" | translate | sentenceCase }}
          </span>
        </button>
        <button
          class="px-2 py-2 rounded-lg bg-transparent border-transparent flex items-center justify-center"
          (click)="handleCancelEdit(optionValue, index)"
          [disabled]="isEditing"
        >
          <span class="font-inter text-sm font-semibold text-gray-600">
            {{ "section-action.cancel" | translate | sentenceCase }}
          </span>
        </button>
      </div>
      <ng-template #deleteBtn>
        <button
          class="px-2 bg-transparent border-transparent flex items-center justify-center {{
            optionValue.value.disabled
              ? 'cursor-default pointer-events-none'
              : 'cursor-pointer pointer-events-auto'
          }}"
          (click)="handleActions(optionValue, 'DELETE')"
        >
          <i
            class="sctr-icon-trash-01 text-xl/5 {{
              optionValue.value.disabled
                ? 'pointer-events-none cursor-default text-gray-500'
                : 'cursor-pointer text-error-600'
            }} "
          ></i>
        </button>
      </ng-template>
    </ng-template>
    <ng-template #loadingTemp>
      <p-progressSpinner
        styleClass="!w-[1.25rem] !h-[1.25rem]"
      ></p-progressSpinner>
    </ng-template>
  </ng-template>
</ng-container>
