import { DateTime } from 'luxon';
import { Injectable, inject } from '@angular/core';
import { CustomTranslateService } from './custom-translate.service';
import { DateTimeFormat } from '../enum/date-time-format.enum';
import { DateFormat } from '../enum/date-format.enum';
import { TimeFormat } from '../enum/time-format.enum';

@Injectable({
  providedIn: 'root',
})
export class CustomDateTimeService {
  private translationService = inject(CustomTranslateService)
  private localeString = this.translationService.getLocale();

  formatDateTime(value: any, format?: DateTimeFormat): string {
    const dateTimeValue = new Date(value).toISOString();
    const dateTimeFormat =
      format && (Object.values(DateTimeFormat) as string[]).includes(format) ? format : 'DATETIME_SHORT';

    return DateTime.fromISO(dateTimeValue)
      .setLocale(this.localeString)
      .toLocaleString((DateTime as any)[`${dateTimeFormat}`]);
  }

  formatDate(value: any, format?: DateFormat): string {
    const dateValue = new Date(value).toISOString();
    const dateFormat =
      format && (Object.values(DateFormat) as string[]).includes(format) ? format : 'DATE_SHORT';
      
    return DateTime.fromISO(dateValue)
      .setLocale(this.localeString)
      .toLocaleString((DateTime as any)[`${dateFormat}`]);
  }

  formatTime(value: any, format?: TimeFormat): string {
    const timeValue = new Date(value).toISOString();
    const timeFormat =
      format && (Object.values(TimeFormat) as string[]).includes(format) ? format : 'TIME_SIMPLE';

    return DateTime.fromISO(timeValue)
      .setLocale(this.localeString)
      .toLocaleString(DateTime[`${timeFormat}`]);
  }
}
