import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DateTime } from 'luxon';
import { CalendarTypeView } from 'primeng/calendar';
import {
  CalendarOptionsChoose,
  CalendarOptionsSelect,
} from 'src/app/core/constants/calendar.config';
import { CalendarFilter, FrameType } from './calendar-filter.model';
import { CustomDateTimeService } from 'src/app/core/services/custom-date-time.service';

@Component({
  selector: 'app-calendar-filter',
  templateUrl: './calendar-filter.component.html',
  styleUrls: ['./calendar-filter.component.scss'],
})
export class CalendarFilterComponent {
  readonly optionsChoose = CalendarOptionsChoose;
  readonly optionsSelect = CalendarOptionsSelect;
  readonly dateNow = new Date();
  private customDateTimeService = inject(CustomDateTimeService);

  @Input() filter: CalendarFilter;
  @Output() filterChange = new EventEmitter<CalendarFilter>();

  date: Date[] | Date;
  code: string;
  title: string;
  dateTitle: string;
  inputOptions: {
    mode: 'single' | 'multiple' | 'range';
    view: CalendarTypeView;
    disabled: boolean;
  };
  optionSelected: string;

  ngOnInit() {
    this.date = new Date();
    this.dateTitle = this.formatDateString(this.date);
    this.inputOptions = {
      mode: 'single',
      view: 'date',
      disabled: false,
    };
    this.optionSelected = this.optionsSelect[0].value;
    this.title = this.optionsSelect[0].title;
    this.code = this.optionsSelect[0].code;
  }

  formatDateString(date: any): string {
    return this.customDateTimeService.formatDate(DateTime.fromJSDate(date));
  }

  onSelectDate(evt: Date) {
    switch (this.optionSelected) {
      case 'byDay': {
        this.date = evt;
        this.dateTitle = this.formatDateString(evt);
        this.onChangeFilter({ frame: 'DAYS', comparison: 1 });
        break;
      }
      case 'byWeek': {
        const start = DateTime.fromJSDate(evt).startOf('week').toJSDate();
        const end = DateTime.fromJSDate(evt).endOf('week').toJSDate();

        this.date = [start, end];
        this.dateTitle = `${this.formatDateString(start)} - ${this.formatDateString(end)}`;
        this.onChangeFilter({ frame: 'DAYS', comparison: 7 });
        break;
      }
      case 'byMonth': {
        this.date = evt;
        this.dateTitle = DateTime.fromJSDate(this.date).toFormat('MM/yyyy');

        const startOfMonth = DateTime.fromJSDate(evt).startOf('month').toJSDate();
        const endOfMonth = DateTime.fromJSDate(evt).endOf('month').toJSDate();

        const comparisonDate = DateTime.fromJSDate(startOfMonth).minus({ day: 1 }).toFormat('MM/yyyy');

        this.filter = {
          frame: 'WEEKS',
          rangeDates: [startOfMonth, endOfMonth],
          comparisonDate: comparisonDate,
        };

        this.filterChange.emit(this.filter);
        break;
      }
      case 'byYear': {
        this.date = evt;
        this.dateTitle = DateTime.fromJSDate(this.date).toFormat('yyyy');

        const startOfYear = DateTime.fromJSDate(evt).startOf('year').toJSDate();
        const endOfYear = DateTime.fromJSDate(evt).endOf('year').toJSDate();

        const comparisonDate = DateTime.fromJSDate(evt).minus({ year: 1 }).toFormat('yyyy');

        this.filter = {
          frame: 'MONTHS',
          rangeDates: [startOfYear, endOfYear],
          comparisonDate: comparisonDate,
        };
        this.filterChange.emit(this.filter);
        break;
      }
    }
  }

  onSelectOption(value: string, title: string, code: string) {
    this.code = code;
    this.title = title;
    this.optionSelected = value;
    switch (value) {
      case 'today': {
        this.inputOptions = {
          mode: 'single',
          view: 'date',
          disabled: true,
        };
        this.date = new Date();
        this.dateTitle = this.formatDateString(this.date);
        this.onChangeFilter({ frame: 'DAYS', comparison: 1 });
        break;
      }
      case 'yesterday': {
        this.inputOptions = {
          mode: 'single',
          view: 'date',
          disabled: true,
        };

        const yesterday = DateTime.now().startOf('day').minus({ day: 1 }).toJSDate();

        this.date = yesterday;
        this.dateTitle = this.formatDateString(yesterday);
        this.onChangeFilter({ frame: 'DAYS', comparison: 1 });
        break;
      }
      case 'pastWeek': {
        this.inputOptions = {
          mode: 'range',
          view: 'date',
          disabled: true,
        };

        const startDate = DateTime.now().startOf('day').minus({ day: 8 }).toJSDate();
        const endDate = DateTime.now().endOf('day').minus({ day: 1 }).toJSDate();

        this.date = [startDate, endDate];
        this.dateTitle = `${this.formatDateString(startDate)} - ${this.formatDateString(endDate)}`;
        this.onChangeFilter({ frame: 'DAYS', comparison: 7 });
        break;
      }
      case 'pastMonth': {
        this.inputOptions = {
          mode: 'range',
          view: 'date',
          disabled: true,
        };

        const startDate = DateTime.now().startOf('day').minus({ day: 31 }).toJSDate();
        const endDate = DateTime.now().endOf('day').minus({ day: 1 }).toJSDate();

        this.date = [startDate, endDate];
        this.dateTitle = `${this.formatDateString(startDate)} - ${this.formatDateString(endDate)}`;
        this.onChangeFilter({ frame: 'WEEKS', comparison: 30 });
        break;
      }
      case 'byDay': {
        this.inputOptions = {
          mode: 'single',
          view: 'date',
          disabled: false,
        };
        this.date = new Date();
        break;
      }
      case 'byWeek': {
        this.inputOptions = {
          mode: 'range',
          view: 'date',
          disabled: false,
        };
        this.date = [];
        break;
      }
      case 'byMonth': {
        this.inputOptions = {
          mode: 'single',
          view: 'month',
          disabled: false,
        };
        this.date = new Date();
        break;
      }
      case 'byYear': {
        this.inputOptions = {
          mode: 'single',
          view: 'year',
          disabled: false,
        };
        this.date = new Date();
      }
    }
  }

  onChangeFilter({
    frame,
    comparison,
  }: {
    frame: FrameType;
    comparison: number;
  }) {
    const type = Array.isArray(this.date) ? 'range' : 'single';
    if (type === 'single') {
      const date = this.date as Date;
      const comparisonDate = this.formatDateString(
        DateTime.fromJSDate(date).minus({ day: comparison }).toJSDate()
      );

      this.filter = {
        frame,
        rangeDates: [
          DateTime.fromJSDate(date).startOf('day').toJSDate(),
          DateTime.fromJSDate(date).endOf('day').toJSDate(),
        ],
        comparisonDate,
      };
    } else if (type === 'range') {
      const range = this.date as Date[];
      const comparisonEndDate = this.formatDateString(
        DateTime.fromJSDate(range[0]).minus({ day: 1 }).toJSDate()
      );
      const comparisonStartDate = this.formatDateString(
        DateTime.fromJSDate(range[0]).minus({ day: comparison }).toJSDate()
      );

      this.filter = {
        frame,
        rangeDates: [
          DateTime.fromJSDate(range[0]).startOf('day').toJSDate(),
          DateTime.fromJSDate(range[1]).endOf('day').toJSDate(),
        ],
        comparisonDate: `${comparisonStartDate} - ${comparisonEndDate}`,
      };
    }
    this.filterChange.emit(this.filter);
  }
}
