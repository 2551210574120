import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab-view',
  templateUrl: './tabview.component.html',
  styleUrls: ['./tabview.component.scss'],
})
export class TabViewComponent {
  @Input({ required: true }) tabs: any[] = [];
  @Input({ required: true }) tabActive: any;
  @Input() translatePrefix = '';
  @Input() isLoading = false;

  @Output() onChange = new EventEmitter<any>();
  @Output() tabActiveChange = new EventEmitter<any>();
  approveWithdrawal="approve-withdrawal."
  onTabChange(value: any) {
    if (value == this.tabActive || this.isLoading) return;
    this.tabActiveChange.emit(value);
    this.onChange.emit(value);
  }
}
