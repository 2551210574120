import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroup } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ITreeItem, ITreeList } from 'src/app/core/services/tree-form.service';
export interface ConfigAutoMaker {
  deleteBtn: boolean;
  iconDeleteClass: string;
  dropdown: boolean;
  edit?: boolean;
}
export interface OptionDropdown {
  name: string;
}
export enum ADMIN_STATUS {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
@Component({
  selector: 'app-tree-child',
  templateUrl: './tree-child.component.html',
  styleUrls: ['./tree-child.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TreeChildComponent implements OnInit {
  constructor(private controlContainer: ControlContainer, public cdr: ChangeDetectorRef) { }
  @Input() parentIndex: number;
  @Input() config: ConfigAutoMaker = {
    deleteBtn: true,
    iconDeleteClass: 'sctr-icon-trash-01 text-error-700',
    dropdown: true,
    edit: false,
  };
  @Input() carType: OptionDropdown[] = [{ name: 'SUV' }, { name: 'Sedan' }];
  @Output() emitDeleteChildItemOrder = new EventEmitter<number>();
  @Output() emitDeleteChildItem = new EventEmitter<number>();
  @Output() emitDropChildItem = new EventEmitter<any>();
  @Output() emitAddChildItem = new EventEmitter<any>();
  @Output() emitEditInput = new EventEmitter<any>();
  @Output() emitActionOption = new EventEmitter<any>();
  @Output() emitOpenAccordion = new EventEmitter<any>();
  @Output() emitCancelEdit = new EventEmitter<any>();
  editingItemValue: ITreeItem | undefined;
  translatePrefix = 'ota-config-management.';
  adminStatus = ADMIN_STATUS;
  isApproving: boolean = false;
  selectedChildItem: FormControl | undefined;
  isEditing: boolean = false;
  initalData: ITreeList;
  get parentFormGroup(): FormGroup {
    return this.controlContainer?.control?.get([this.parentIndex]) as FormGroup;
  }
  get approvedItems(): FormArray {
    return this.parentFormGroup.get('approvedItems') as FormArray;
  }
  get unapprovedItems(): FormArray {
    return this.parentFormGroup.get('unapprovedItems') as FormArray;
  }
  ngOnInit(): void {
    this.initalData = this.parentFormGroup.value;
  }
  handlePreventCollapse(event: Event) {
    event.stopPropagation();
  }
  typeEdit(item: any): void {
    this.editingItemValue = item.value;
  }
  checkIsContainItems(items: any[]): boolean {
    return items.filter(item => !item.is_deleted).length > 0;
  }
  addChildItem() {
    this.emitAddChildItem.emit()
  }
  // Handle drag child item - Approved items only
  dropChild(event: CdkDragDrop<any>) {
    const sourceIndex = event.container.data.value[event.previousIndex].order_value;
    const targetIndex = event.container.data.value[event.currentIndex].order_value;
    this.emitDropChildItem.emit({
      item: this.approvedItems.value[event.previousIndex],
      offset: targetIndex - sourceIndex,
    });
    this.moveItemInFormArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );
    this.reorderChildItems(this.approvedItems.controls as FormGroup[], event.currentIndex);
  }
  moveItemInFormArray(
    formArray: FormArray,
    srcIndex: number,
    targetIndex: number,
  ): void {
    const formGroup = formArray.at(srcIndex) as FormGroup;
    formArray.removeAt(srcIndex);
    formArray.insert(targetIndex, formGroup);
    formArray.markAsDirty();
  }
  reorderChildItems(childListGroup: FormGroup[], startIndex: number) {
    childListGroup.forEach((item: FormGroup, index: number) => {
      if (index >= startIndex) {
        if (item.get('index')) item!.get('index')!.setValue(index + 1);
        item?.markAsDirty();
      }
    });
  }

  handleActions(
    actionFormControl: FormControl,
    type: 'APPROVE' | 'REJECT' | 'DELETE' | 'EDIT',
  ) {
    switch (type) {
      case 'APPROVE':
        this.isApproving = true;
        this.selectedChildItem = actionFormControl;
        break;
      case 'REJECT':
        this.selectedChildItem = actionFormControl;
        break;
      case 'EDIT':
        this.isEditing = true;
        this.editingItemValue = actionFormControl.value;
        break;
      case 'DELETE':
        break;
    }
    this.emitActionOption.emit({ optionValue: actionFormControl, type: type, editingItem: this.editingItemValue });
  }
  handleCancelEdit(item: any, optionIndex?: number) {
    if (optionIndex !== undefined) {
      if (item.value?.status === ADMIN_STATUS.APPROVED) {
        const initialItem = this.initalData.approvedItems![optionIndex];
        item.patchValue({
          name: initialItem.name,
          selectedType: initialItem.selectedType,
        });
      } else {
        const initialItem = this.initalData.unapprovedItems![optionIndex];
        item.patchValue({
          name: initialItem.name,
          selectedType: initialItem.selectedType,
        });
      }
    } else {
      item.patchValue({
        name: this.initalData.name,
      });
    }
    this.editingItemValue = undefined;
  }
}
