<ul class="grid grid-cols-2 md:grid-cols-4  items-center bg-white gap-2 p-4">
  <li
    (click)="onTabChange(tab.value)"
    *ngFor="let tab of tabs; let i = index"
    [ngClass]="{
      'bg-blue-50 text-blue-600': tabActive === tab.value,
      'hover:bg-blue-50 hover:text-blue-500': !isLoading
    }"
    class="group flex-1 flex items-center justify-center gap-2 rounded-lg px-2 py-2 max-w-fit cursor-pointer"
  >
    <span
      [ngClass]="[tabActive === tab.value ? 'text-blue-700' : 'text-gray-500']"
      class="text-sm font-semibold"
    >
      {{ approveWithdrawal + tab.title.toLowerCase() | translate | sentenceCase }}
    </span>
    <span
      [ngClass]="[
        tabActive === tab.value
          ? 'bg-blue-600 text-white'
          : 'bg-gray-100 text-black',
        isLoading ? '' : 'group-hover:bg-blue-600 group-hover:text-white'
      ]"
      class="text-xs py-1 px-2 rounded-2xl min-w-[24px] flex items-center justify-center"
    >
      {{ tab.quantity }}
    </span>
  </li>
</ul>
