import { Component, Input } from '@angular/core';
import { FileService } from 'src/app/core/services/file.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-custom-image',
  templateUrl: './custom-image.component.html',
  styleUrls: ['./custom-image.component.scss'],
})
export class CustomImageComponent {
  @Input({ required: true }) imgId = '';
  @Input({ required: true }) alt = '';

  @Input() class = '';
  @Input() imageClass = '';
  @Input() isAvatar = false;
  imageLoader = true;

  constructor(public fileService: FileService) {}

  ngOnChanges() {
    if (this.fileService.isYoutubeUrl(this.imgId)) {
      const id = this.fileService.getYoutubeId(this.imgId);
      this.imgId = this.fileService.getThumbnailYoutube(id);
    } else if (this.fileService.isVideoUrl(this.imgId)) {
      this.imgId = this.fileService.convertWebmToWebp(this.imgId);
    } else if (this.isAvatar) {
      if ( this.imgId && this.imgId.includes('/'))
        this.imgId = this.fileService.getImgPng(this.imgId);
      else this.imgId = this.fileService.getThumbnailWebp(this.imgId);
    } else {
      this.imgId = this.fileService.getThumbnailWebp(this.imgId);
    }
  }

  onImageError() {
    this.imageLoader = false;
    this.imgId = 'assets/imgs/default/image.webp';
  }

  onImageLoad() {
    this.imageLoader = false;
  }
}
