<div
  *ngIf="totalRecords > RowsPerPageOptions[0] && !isLoading"
  [class]="'t-paginator flex justify-around md:justify-between px-6 py-2 bg-white ' + stylesClass"
>
  <!-- ROW PAGINATION -->
  <div class="max-md:hidden flex items-center gap-2">
    <span class="text-sm">
      {{ "common.rows-per-page" | translate | sentenceCase }}:
    </span>
    <p-dropdown
      class="t-custom-pagination"
      (ngModelChange)="onRowsChange($event)"
      [ngModel]="pagination.rows"
      [options]="RowsPerPageOptions"
      appendTo="body"
    ></p-dropdown>
    <span class="text-sm" *ngIf="!isHiddenRowText">
      {{ "common.rows" | translate }}
    </span>
  </div>
  <!-- PAGE PAGINATION -->
  <div class="flex items-center gap-2">
    <span *ngIf="!isCompact" class="max-lg:hidden text-sm">
      {{ pagination.rows * pagination.page + 1 }} -
      {{ pagination.rows * pagination.page + dataLength }}
      {{ "common.of" | translate }}
      {{ totalRecords }}
    </span>
    <p-paginator
      (onPageChange)="onPageChange($event)"
      [first]="pagination.rows * pagination.page"
      [rows]="pagination.rows"
      [totalRecords]="totalRecords"
    ></p-paginator>
  </div>
</div>
