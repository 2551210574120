<div (click)="op.toggle($event)" class="relative w-[350px] flex-1">
  <div
    class="flex !h-11 w-full cursor-pointer items-center justify-start rounded-lg border bg-white transition hover:border-blue-600"
  >
    <span class="mr-2 indent-4 text-sm font-semibold">
      {{ 'component.calendar_filter.' + code | translate }}:
    </span>
    <span class="text-sm">{{ dateTitle }}</span>
    <i
      class="sctr-icon-calendar absolute right-4 top-1/2 translate-y-[-50%] cursor-pointer"
    ></i>
  </div>
</div>
<p-overlayPanel #op>
  <div class="flex min-w-[500px] -m-5 gap-2">
    <div class="border-r py-4 pr-2">
      <ul class="flex flex-col gap-1 px-2">
        <li
          *ngFor="let option of optionsChoose"
          class="cursor-pointer rounded px-4 py-1 text-sm font-medium hover:bg-gray-100"
          [ngClass]="{ 'text-primary-600': option.value === optionSelected }"
          (click)="onSelectOption(option.value, option.title, option.code)"
        >
          {{ 'component.calendar_filter.' + option.code | translate }}
        </li>
      </ul>
      <ul class="mt-3 flex flex-col gap-1 border-t px-2 pt-3">
        <li
          *ngFor="let option of optionsSelect"
          class="flex cursor-pointer items-center justify-between rounded px-4 py-1 text-sm font-medium hover:bg-gray-100"
          [ngClass]="{ 'text-primary-600 bg-blue-50': option.value === optionSelected }"
          (click)="onSelectOption(option.value, option.title, option.code)"
        >
          {{ 'component.calendar_filter.' + option.code | translate }}
          <i class="pi pi-angle-right"></i>
        </li>
      </ul>
    </div>
    <div class="flex-1">
      <div class="calendar-from-input-booking">
        <p-calendar
          styleClass="w-full"
          panelStyleClass="!border-0"
          [maxDate]="dateNow"
          [ngModel]="date"
          [inline]="true"
          [selectionMode]="inputOptions.mode"
          [view]="inputOptions.view"
          [disabled]="inputOptions.disabled"
          [readonlyInput]="true"
          (onSelect)="onSelectDate($event)"
        ></p-calendar>
      </div>
    </div>
  </div>
</p-overlayPanel>
