import { Injectable } from '@angular/core';
import { decodeBase64 } from 'tweetnacl-util';
import { box } from 'tweetnacl';
import { environment } from 'src/environments/environment';
import { ApiClient } from 'src/app/lib/api/api-client';
import { AuthAPI } from 'src/app/lib/api/auth/api.auth.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private api: ApiClient;
  url = environment.FE_URL;
  // urlSso = environment.SSO;
  constructor(readonly http: HttpClient) {
    this.api = new ApiClient(http);
  }
  decodeToken(queryParam: any) {
    const { ciphertext, ephemPubKey, nonce } = queryParam;
    let token = '';
    if (ciphertext && ephemPubKey && nonce) {
      token = new TextDecoder().decode(
        box.open(
          decodeBase64(ciphertext),
          decodeBase64(nonce),
          decodeBase64(ephemPubKey),
          decodeBase64(localStorage.getItem('privateKey')!),
        )!,
      );
    }
    return token;
  }

  redirectToSso() {
    // const myKeyPair = box.keyPair();
    // localStorage.setItem("privateKey", encodeBase64(myKeyPair.secretKey));
    // window.location.href =
    //   this.urlSso +
    //   "login/?returnURL=" +
    //   encodeURIComponent(window.location.href) +
    //   "&publickey=" +
    //   encodeURIComponent(encodeBase64(myKeyPair.publicKey));
  }

  checkSsoRedirect(queryParam: any) {
    const { ciphertext, ephemPubKey, nonce } = queryParam;
    return ciphertext && ephemPubKey && nonce;
  }

  login(data: AuthAPI.SignIn): Observable<any> {
    return this.api.auth.signIn(data);
  }

  logout(data: AuthAPI.SignOut) {
    return this.api.auth.signOut(data);
  }

  isLoggedIn() {
    const loggedIn = localStorage.getItem('STATE');
    let isLogin = false;
    isLogin = (loggedIn == 'true');
    return isLogin;
  }

  getRole() {
    return localStorage.getItem('ROLE')
  }

  refreshToken(refreshToken: string) {
    return this.api.auth.refreshToken(refreshToken);
  }
}
